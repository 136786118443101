import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../layout'

const LegalPage = ({data}) => {
  return (
    <Layout title="Mentions légales">
      {data.allBikiniSection.edges.map(({node: {slug, title, content}}) => (
        <div key={slug}>
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{__html: content}} />
        </div>
      ))}
    </Layout>
  )
}

export default LegalPage

export const query = graphql`
  query {
    allBikiniSection(filter: {page: {eq: "legal"}}, sort: {fields: [order], order: ASC}) {
      edges {
        node {
          slug
          title
          content
        }
      }
    }
  }
`
